<template>
    <k-layout :navProps="{ variant: 'dark' }">
        <div v-if="item" class="tw-h-screen">
            <section class="tw-bg-gray-900">
                <k-container class="tw-py-6 sm:tw-py-12">
                    <h1 class="tw-heading-1 tw-text-gray-50">{{ item.title }}</h1>
                </k-container>
            </section>

            <iframe ref="iframeElement" :srcdoc="item.url" class="tw-w-full tw-h-full tw-bg-red"></iframe>
        </div>
    </k-layout>
</template>

<script>
export default {
    created() {
        const { id } = this.$route.params;

        this.$store.dispatch("NEWSFEED/fetchById", id).catch(() => {
            this.$router.push({ name: "Page404", params: { "0": "" } }).catch(() => {});
        });

        this.$vuetify.goTo(0);
    },

    computed: {
        item() {
            const { id } = this.$route.params;
            return this.$store.getters["NEWSFEED/newsfeedById"](id);
        }
        // externalURL() {
        //     if (this.item.url) {
        //         const url = new URL(this.item.url);
        //         url.searchParams.append("utm_source", "Koderia.sk");
        //         return url.toString();
        //     } else {
        //         return "";
        //     }
        // }
    },

    watch: {
        "item.url"(value) {
            fetch(`https://cors-proxy.htmldriven.com/?url=${value}`).then(async response => {
                this.$refs.iframeElement.src = "about:blank";
                this.$refs.iframeElement.contentWindow.document.open();
                this.$refs.iframeElement.contentWindow.document.write(await response.text());
                this.$refs.iframeElement.contentWindow.document.close();

                const baseEl = this.$refs.iframeElement.contentWindow.document.createElement("base");
                baseEl.setAttribute("href", value);
                baseEl.setAttribute("target", "_top");
                this.$refs.iframeElement.contentWindow.document.head.appendChild(baseEl);
            });
        }
    }
};
</script>
